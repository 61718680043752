"use client";

import { FC, useMemo } from "react";

import {
  CAT_FOOD_BRAND,
  COMPETITOR_MAP_LAST_UPDATED,
  competitorMap,
  ICompetitorFood,
  TFoodBrand,
} from "libs/cat-calculations/src/lib/cat-calculations.constants";
import { themeRootClassMap } from "apps/website/maps/Theme.map";
import TextTitle from "apps/website/components/base/Text/TextTitle/TextTitle";
import List from "apps/website/components/base/List/List";

import Grid from "../../../layout/Grid/Grid";
import Column from "../../../layout/Column/Column";
import TextSubtitle from "../../../base/Text/TextSubtitle/TextSubtitle";
import TextBody from "../../../base/Text/TextBody/TextBody";
import Spacer from "../../../layout/Spacer/Spacer";
import Button from "../../../base/Button/Button";
import CondensedReviewListItem from "../../CondensedReviewList/CondensedReviewListItem/CondensedReviewListItem";

export interface IPriceCalculatorResultProps {
  katkinPrice: number;
  katkinCalories: number;
  competitorPortion?: ICompetitorFood;
  competitor: TFoodBrand;
  signUpFlowParams: string;
}

enum EPricePoint {
  CHEAPER,
  MORE_EXPENSIVE,
  MUCH_MORE_EXPENSIVE,
  UNKNOWN,
}

interface IResultsTableItem {
  title: string;
  meatPercentage: number;
}

const PriceCalculatorResult: FC<IPriceCalculatorResultProps> = ({
  katkinPrice,
  katkinCalories,
  competitorPortion,
  competitor,
  signUpFlowParams,
}) => {
  const getPricePoint = useMemo<EPricePoint>(() => {
    if (!competitorPortion) return EPricePoint.UNKNOWN;
    const priceDifference = katkinPrice - competitorPortion.costPerDay;

    if (priceDifference > 0) return EPricePoint.CHEAPER;
    if (priceDifference > -1) return EPricePoint.MORE_EXPENSIVE;

    return EPricePoint.MUCH_MORE_EXPENSIVE;
  }, [ katkinPrice, competitorPortion ]);

  const getMeatPercentageDifference = useMemo<number>(
    () => 100 - (competitorPortion?.meatPercentage || 0),
    [ katkinPrice, competitorPortion ],
  );

  const getMonthlyDifferenceInPrice = useMemo<number>(
    () => +(katkinPrice * 28 - (competitorPortion?.costPerDay || 0) * 28).toFixed(),
    [ katkinPrice, competitorPortion ],
  );

  const getTitleCopy = useMemo<string>(() => {
    if (
      getPricePoint === EPricePoint.UNKNOWN ||
      getMeatPercentageDifference === 100
    ) return `For just £${katkinPrice.toFixed(
      2,
    )} per day, your cat will get 96% more meat than commercial brands.`;
    if (getPricePoint === EPricePoint.CHEAPER) return `For just £${Math.abs(
      katkinPrice - (competitorPortion?.costPerDay || 0),
    ).toFixed(
      2,
    )} more per day*, your cat will get ${getMeatPercentageDifference}% more meat.`;

    if (getPricePoint === EPricePoint.MORE_EXPENSIVE) return `Great news - you'll save £${Math.abs(
      getMonthlyDifferenceInPrice,
    )} per month*. For ${getMeatPercentageDifference}% more meat.`;

    return `For just £${katkinPrice} per day*, your cat will get ${getMeatPercentageDifference}% more meat.`;
  }, [ getPricePoint ]);

  const getFooterCopy = useMemo<string>(() => {
    if (
      getPricePoint === EPricePoint.UNKNOWN ||
      getPricePoint === EPricePoint.MUCH_MORE_EXPENSIVE ||
      getMeatPercentageDifference === 100
    ) return `*Cost estimate based on ${katkinCalories} calories per day.`;
    return `*Price + ingredient comparison as of ${COMPETITOR_MAP_LAST_UPDATED}.`;
  }, [ getPricePoint ]);

  const getTableContents = useMemo<IResultsTableItem[]>(() => {
    const itemKatKin: IResultsTableItem = {
      title: "Katkin",
      meatPercentage: 100,
    };
    if (getPricePoint === EPricePoint.UNKNOWN) {
      return [
        itemKatKin,
        {
          title: CAT_FOOD_BRAND.FELIX,
          meatPercentage: competitorMap.FELIX?.Wet?.A.meatPercentage || 0,
        },
        {
          title: CAT_FOOD_BRAND.WHISKAS,
          meatPercentage: competitorMap.WHISKAS?.Dry?.A.meatPercentage || 0,
        },
        {
          title: CAT_FOOD_BRAND.GOURMET,
          meatPercentage: competitorMap.GOURMET?.Wet?.A.meatPercentage || 0,
        },
      ];
    }
    return [
      itemKatKin,
      {
        title: CAT_FOOD_BRAND[competitor],
        meatPercentage: competitorPortion?.meatPercentage || 0,
      },
    ];
  }, [ katkinPrice, competitorPortion, getPricePoint ]);

  return (
    <div
      data-component={PriceCalculatorResult.name}
      className={`${themeRootClassMap.light} w-full p-4`}
      data-theme="light"
    >
      <Grid>
        <Column justify="center" align="center">
          <TextSubtitle size={2} align="center">
            { getTitleCopy }
          </TextSubtitle>

          <Spacer size="lg" />
          <Grid gap="none" className="w-full max-w-[500px]">
            <>
              { getTableContents.map((item, index) => (
                <>
                  <Column spans={4} justify="center" key={item.title}>
                    <TextSubtitle
                      tag="span"
                      size={2}
                      data-testid={`graph-title-${index}`}
                    >
                      { item.title }
                    </TextSubtitle>
                  </Column>
                  <Column
                    key={item.title}
                    spans={8}
                    justify="center"
                    className={`border-l-2 border-black border-solid ${
                      index === getTableContents.length - 1
                        ? "mb-2 border-b-2 border-solid"
                        : ""
                    }`}
                  >
                    <div
                      className={`border-r-2 border-y-2 border-black border-solid h-12 my-2 ${
                        index === 0 ? "mt-4" : ""
                      } ${
                        index === getTableContents.length - 1 ? "mb-4" : ""
                      } ${
                        item.meatPercentage === 100 ? "bg-green" : "bg-orange"
                      }`}
                      style={{ width: `${item.meatPercentage}%` }}
                    >
                      <span
                        className="sr-only"
                        data-testid={`graph-percentage-${index}`}
                      >
                        { item.title } has { item.meatPercentage }% meat content
                      </span>
                    </div>
                  </Column>
                </>
              )) }
            </>
            <Column spans={4}>
              <></>
            </Column>
            <Column spans={8}>
              <Grid>
                <Column direction="row" justify="between">
                  <TextTitle tag="span" size={4}>
                    0%
                  </TextTitle>
                  <TextTitle tag="span" size={4}>
                    50%
                  </TextTitle>
                  <TextTitle tag="span" size={4}>
                    100%
                  </TextTitle>
                </Column>
                <Column>
                  <TextBody size={3}>Percentage of meat (declared).</TextBody>
                </Column>
              </Grid>
            </Column>
          </Grid>
          <Spacer size="lg" />

          <TextSubtitle tag="p" size={2} align="center">
            Which means a healthier + happier cat. Just like Samwell + Kitty:
          </TextSubtitle>
          <Spacer size="lg" />
          <List className="max-w-[500px]">
            <CondensedReviewListItem
              avatar={{
                src: "/images/sections/price-calculator/ellie-samwell-kitty.webp",
                width: 60,
                height: 60,
              }}
              theme="light-grey"
              body="My cats love this real meat cat food – their coats are shiner, their eyes are brighter and they're behaving more like real carnivore cats."
              author="Ellie, Samwell + Kitty"
            />
          </List>
          <Spacer size="lg" />
          <Button to={`/forms/fresh-new-user?${signUpFlowParams}`}>
            Try now with 20% off
          </Button>
          <Spacer size="lg" />
          <TextBody size={3}>{ getFooterCopy }</TextBody>
        </Column>
      </Grid>
    </div>
  );
};

export default PriceCalculatorResult;
